class AuthController {
    constructor(User, Modal, Navigation, Config, $state, $filter) {
        "ngInject";

        this.User = User;
        this.Modal = Modal;
        this.Navigation = Navigation;
        this.Config = Config;

        this.$state = $state;
        this.$translate = $filter('translate');
    }

    $onInit() {
    }
}

export default AuthController;
