class SlotController {
    constructor(User, Navigation, Kids, Calendar, Alert, $filter, $state, $stateParams) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Alert = Alert;
        this.Calendar = Calendar;
        this.Kids = Kids;

        this.$state = $state;
        this.$stateParams = $stateParams;

        this.slots = [];
        this.slotable = null;

        this.$translate = $filter('translate');
        this.formatDate = $filter('formatDate');
    }

    $onInit() {
        this.Navigation.setTitle(this.$translate('CALENDAR_ACTIVITIES_TITLE'));

        this.slotsResolver.then((data) => {
            let { slotable = null, slots = null } =  data;

            this.slotable = slotable;
            this.slots = slots.sort((a,b) => a.date > b.date ? 1 : -1);

            this.Navigation.setTitle(this.slotable.name);
        });

        this.userStayDays = moment(this.User.getUser().leaveAt).diff(this.User.getUser().arrivalAt, 'days');
    }

    canBookSpecialRestaurant() {
        if (this.User.getUser().mode === 3 && this.User.getUser().site.category === '5B') {
            return true;
        }

        if (this.userStayDays >= 4 && this.userStayDays < 11) {
            return this.User.getUser().restaurantSpecialCount < 1;
        }
        else if (this.userStayDays >= 11) {
            return this.User.getUser().restaurantSpecialCount < 2;
        }

        return false;
    }

    isDisabled(slot) {
        if (slot.slotableConfig.slotable.type === 'RESTAURANT' &&
            !slot.slotableConfig.slotable.default &&
            !this.canBookSpecialRestaurant()) {
            return true;
        }

        return slot.seatsAvailable === 0 && slot.seats !== 0;
    }

    sortSlots(slots) {
        return slots.sort((a,b) => a.startAt > b.startAt ? 1 : -1);
    }

    buttonAction(slot) {
        if (this.isDisabled(slot) && !slot.reserved) {
            return;
        }

        if (!slot.reserved) {
            if (this.$stateParams.kidId) {
                this.showConfirmKid(slot);
            } else {
                if (slot.slotableConfig.slotable.type === 'RESTAURANT' && !slot.slotableConfig.slotable.default) {
                    this.showConfirmRestaurantSpecial(slot);
                } else {
                    this.showPrompt(slot);
                }
            }
        } else {
            this.showConfirmDelete(slot);
        }
    }

    showPrompt(slot) {
        this.Alert.showPromptNumber({
            title: this.$translate('CALENDAR_SLOT_BOOK'),
            text: this.$translate('CALENDAR_SLOT_BOOK_PERSON_NUMBER'),
            defaultValue: this.User.getUser().personNumber,
            maxValue: this.User.getUser().personNumber,
            button: {
                label: this.$translate('VALIDATE'),
                classes: ['green', 'bold'],
            },
        }).then((seats) => {
            this.Calendar.postSlot(slot.id, seats).then(() => {
                slot.reserved = true;
                this.Calendar.resetCache();
                slot.reservedSeats = seats;
                slot.seatsAvailable -= seats;
            }).catch((data) => {
                this.Alert.showToast({
                    text: this.$translate(data.data),
                    duration: 2500,
                });
            })
        });
    }

    showConfirmDelete(slot) {
        this.Alert.showConfirm({
            title: this.$translate('CALENDAR_SLOT_DELETE_BOOKING'),
            text: this.$translate('CALENDAR_SLOT_DELETE_BOOKING_MESSAGE'),
            button: {
                label: this.$translate('VALIDATE'),
                classes: ['green', 'bold'],
            },
        }).then(() => {
            this.Calendar.deleteSlot(slot.id, this.$stateParams.kidId).then(() => {
                slot.reserved = false;
                if (!this.$stateParams.kidId) {
                    if (slot.slotableConfig.slotable.type === 'RESTAURANT' && !slot.slotableConfig.slotable.default) {
                        slot.seatsAvailable += this.User.getUser().personNumber;
                        this.User.getUser().restaurantSpecialCount -= 1;
                        this.User.getUser().restaurantsAlreadyBookedCount -= this.User.getUser().personNumber;
                        
                        this.User.resetCache();
                    } else {
                        if (slot.reservedSeats) {
                            slot.seatsAvailable += slot.reservedSeats;
                        }
                    }
                }
                else {
                    slot.seatsAvailable += 1;
                }
            }).catch((data) => {
                this.Alert.showToast({
                    text: this.$translate(data.data),
                    duration: 2500,
                });
            });
        })
    }

    showConfirmKid(slot) {
        if (this.User.getUser().mode !== 1 && slot.slotableConfig.extra) {
            this.Kids.getChildData(this.$stateParams.kidId).then((response) => {
                let kid = response.data;

                let birthDate = kid.form.groups[0].fields.find((field) => {
                    return field.name === 'birthDate';
                });

                let age = moment().diff(birthDate.value, 'year');

                this.Alert.showConfirm({
                    title: this.$translate('USER_OVERAGE_CONFIRM_TITLE'),
                    text: this.$translate('USER_OVERAGE_KID_CONFIRM_TEXT', {
                        total: age >= 12 ? 22 : 12,
                    }),
                    button: {
                        label: this.$translate('USER_OVERAGE_CONFIRM_ACTION'),
                        classes: ['bold'],
                    },
                }).then(() => {
                    this.submitConfirmKid(slot, age >= 12);
                });
            });
        }
        else {
            this.Alert.showConfirm({
                title: this.$translate('CALENDAR_SLOT_BOOK'),
                text: this.$translate('CALENDAR_SLOT_BOOK_MESSAGE'),
                button: {
                    label: this.$translate('VALIDATE'),
                    classes: ['green', 'bold'],
                },
            }).then(() => {
                this.submitConfirmKid(slot);
            });
        }
    }

    submitConfirmKid(slot, isAdult = null) {
        this.Calendar.postSlot(slot.id, 1, this.$stateParams.kidId, isAdult).then(() => {
            slot.reserved = true;
            slot.seatsAvailable -= 1;
        }).catch((data) => {
            this.Alert.showToast({
                text: this.$translate(data.data),
                duration: 2500,
            });
        });
    }

    showConfirmRestaurantSpecial(slot) {
        if (this.User.isOverage()) {
            this.Alert.showConfirm({
                title: this.$translate('USER_OVERAGE_CONFIRM_TITLE'),
                text: this.$translate('USER_OVERAGE_CONFIRM_TEXT', {
                    total: (this.User.getUser().adultsCount * 22) + (this.User.getUser().childrenCount * 11),
                }),
                button: {
                    label: this.$translate('USER_OVERAGE_CONFIRM_ACTION'),
                    classes: ['bold'],
                },
            }).then(() => {
                this.submitConfirmRestaurantSpecial(slot);
            });
        }
        else {
            this.Alert.showConfirm({
                title: this.$translate('CALENDAR_SLOT_BOOK'),
                text: this.$translate('CALENDAR_SLOT_BOOK_MESSAGE'),
                button: {
                    label: this.$translate('VALIDATE'),
                    classes: ['bold'],
                },
            }).then(() => {
                this.submitConfirmRestaurantSpecial(slot);
            });
        }
    }

    submitConfirmRestaurantSpecial(slot) {
        this.Calendar.postSlot(slot.id, this.User.getUser().personNumber, this.$stateParams.kidId).then(() => {
            slot.reserved = true;
            slot.seatsAvailable -= this.User.getUser().personNumber;
            this.User.getUser().restaurantSpecialCount += 1;
            this.User.getUser().restaurantsAlreadyBookedCount += this.User.getUser().personNumber;

            this.User.resetCache();
            this.Calendar.resetCache();
        }).catch((data) => {
            this.Alert.showToast({
                text: this.$translate(data.data),
                duration: 2500,
            });
        });
    }
}

export default SlotController;
