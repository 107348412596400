class BookingController {
    constructor(User, Http, Alert, Navigation, Calendar, $state, $stateParams, $q, $filter) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Alert = Alert;
        this.Calendar = Calendar;
        this.Http = Http;

        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$q = $q;

        this.$translate = $filter('translate');
        this.formatDate = $filter('formatDate');

        this.groups = null;
        this.slots = null;
    }

    $onInit() {
        this.Navigation.setTitle(this.$translate('CALENDAR_BOOKING_TITLE'));

        this.groupsAndSlotsResolver.then((groupsAndSlots) => {
            let { groups = null, slots = null } = groupsAndSlots;

            this.slots = slots;

            this.slots.forEach((slot) => {
                if (slot.id === 16) {
                    slot.seatsAvailable = 2;
                }

                return slot;
            });

            if (groups.length > 0) {
                this.groups = groups.map((group) => {
                    group.slot = this.slots.find((slot) => {
                        return slot.id === group.slot.id;
                    });

                    return group;
                });
            }
            else {
                this.addDefaultGroup();
            }
        });
    }

    addDefaultGroup() {
        let seats = this.User.getUser().personNumber;

        let group = {
            seats: 0,
            slot: this.getSlotsForType(this.$stateParams.type).find((slot) => {
                return this.seatsCanFitInSlot(seats, slot);
            }),
        };

        this.groups = [];

        this.incrementSeats(group, seats);

        this.groups.push(group);
    }

    hasMoreThanOneGroup() {
        return this.groups.length > 1;
    }

    getButtonGroupLabel() {
        if (this.groups && this.groups.length === 1) {
            return this.$translate('CALENDAR_BOOKING_SPLIT')
        } else {
            return this.$translate('CALENDAR_BOOKING_ADD_GROUP')
        }
    }

    getDay() {
        if (!this.groups) {
            return;
        }

        return this.formatDate(this.$stateParams.date, 'localized');
    }

    getSlotsForGroup(group) {
        return this.getSlotsForType(group.slot.slotableConfig.type).sort((a,b) => a.startAt > b.startAt ? 1 : -1);
    }

    getSlotsForType(type) {
        return this.slots.filter((slot) => {
            return slot.slotableConfig.type === type;
        });
    }

    getMealName(group) {
        return this.Calendar.getMeals().find((meal) => {
            return meal.type === group.slot.slotableConfig.type;
        }).name;
    }

    groupCanFitInSlot(group, slot) {
        return this.seatsCanFitInSlot(group.seats, slot);
    }

    seatsCanFitInSlot(seats, slot) {
        return slot.seatsAvailable >= seats || slot.extra;
    }

    setSlot(group, slot) {
        if (group.slot.id === slot.id) {
            return;
        }

        if (!this.groupCanFitInSlot(group, slot)) {
            return;
        }

        group.slot.seatsAvailable += group.seats;
        group.slot = slot;
        group.slot.seatsAvailable -= group.seats;
    }

    addGroup() {
        if (this.groups.length === 0) {
            this.addDefaultGroup();

            return;
        }

        let fromGroup = this.groups.find((group) => {
            return group.seats > 1;
        });

        this.incrementSeats(fromGroup, -1);

        let toGroup = {
            seats: 0,
            slot: this.getSlotsForGroup(fromGroup).find((slot) => {
                return this.seatsCanFitInSlot(1, slot);
            }),
        };

        this.incrementSeats(toGroup, 1);

        this.groups.push(toGroup);
    }

    removeGroup(index) {
        let fromGroup = this.groups[index];

        let toGroup = this.groups[0];

        fromGroup.slot.seatsAvailable += fromGroup.seats;

        if (this.seatsCanFitInSlot(fromGroup.seats, toGroup.slot)) {
            toGroup.seats += fromGroup.seats;
            toGroup.slot.seatsAvailable -= fromGroup.seats;
        } else {
            toGroup.slot.seatsAvailable += toGroup.seats;

            toGroup.seats += fromGroup.seats;

            toGroup.slot = this.getSlotsForGroup(toGroup).find((slot) => {
                return this.seatsCanFitInSlot(toGroup.seats, slot);
            });

            toGroup.slot.seatsAvailable -= toGroup.seats;
        }

        this.groups.splice(index, 1);
    }

    incrementSeats(group, increment) {
        let seats = group.seats + increment;

        if (increment > 0) {
            let totalSeats = this.groups.reduce((accumulator, value) => {
                return accumulator + value.seats;
            }, 0);

            if (totalSeats >= this.User.getUser().personNumber) {
                let groupWithMoreThanOneSeat = this.groups.find((value) => {
                    if (group === value) {
                        return false;
                    }

                    return value.seats > 1;
                });

                if (!groupWithMoreThanOneSeat) {
                    this.Alert.showConfirm({
                        title: this.$translate('CALENDAR_BOOKING_ERROR'),
                        text: this.$translate('CALENDAR_BOOKING_TOO_MANY_SEATS_MESSAGE'),
                    });

                    return;
                }

                this.incrementSeats(groupWithMoreThanOneSeat, -1);
            }

            if (!this.seatsCanFitInSlot(increment, group.slot)) {
                this.Alert.showConfirm({
                    title: this.$translate('CALENDAR_BOOKING_ERROR'),
                    text: this.$translate('CALENDAR_BOOKING_NOT_ENOUGH_SEATS_MESSAGE'),
                });

                return;
            }
        }

        if (increment < 0) {
            if (seats < 1) {
                this.Alert.showConfirm({
                    title: this.$translate('CALENDAR_BOOKING_ERROR'),
                    text: this.$translate('CALENDAR_BOOKING_MINIMUM_SEATS_MESSAGE'),
                });

                return;
            }
        }

        group.seats += increment;
        group.slot.seatsAvailable -= increment;
    }

    deleteBooking() {
        this.Alert.showConfirm({
            title: this.$translate('CALENDAR_BOOKING_DELETE_BOOKING'),
            text: this.$translate('CALENDAR_BOOKING_DELETE_BOOKING_MESSAGE'),
            button: {
                label: this.$translate('DELETE'),
                classes: ['red', 'bold'],
            },
        }).then(() => {
            this.groups = [];

            this.submit();
        });
    }

    addBooking() {
        if (this.User.isOverage() || !this.User.isIncluded(this.$stateParams.type)) {
            let promises = [];

            this.groups.forEach((group) => {
                let seats = group.seats;
                let maxAdults = seats < this.User.getUser().adultsCount ? seats : this.User.getUser().adultsCount;

                promises.push(
                    this.Alert.showPromptNumber({
                        title: this.$translate('USER_OVERAGE_ADULTS_COUNT_TITLE'),
                        text: this.$translate('USER_OVERAGE_ADULTS_COUNT_TEXT'),
                        defaultValue: maxAdults,
                        minValue: 0,
                        maxValue: maxAdults,
                        button: {
                            label: this.$translate('VALIDATE'),
                            classes: ['green', 'bold'],
                        },
                    }).then((adultsCount) => {
                        seats -= adultsCount;
                        let maxChildren = seats < this.User.getUser().childrenCount ? seats : this.User.getUser().childrenCount;

                        if (maxChildren > 0) {
                            return this.Alert.showPromptNumber({
                                title: this.$translate('USER_OVERAGE_CHILDREN_COUNT_TITLE'),
                                text: this.$translate('USER_OVERAGE_CHILDREN_COUNT_TEXT'),
                                defaultValue: maxChildren,
                                minValue: 0,
                                maxValue: maxChildren,
                                button: {
                                    label: this.$translate('VALIDATE'),
                                    classes: ['green', 'bold'],
                                },
                            }).then((childrenCount) => {
                                group.seats = adultsCount + childrenCount;
                                group.adultsCount = adultsCount;
                                group.childrenCount = childrenCount;
                            });
                        }
                        else {
                            group.seats = adultsCount;
                            group.adultsCount = adultsCount;
                            group.childrenCount = 0;
                        }
                    })
                );
            });

            this.$q.all(promises).then(() => {
                let adultsCount = this.groups.reduce((total, group) => {
                    return group.adultsCount + total;
                }, 0);

                let childrenCount = this.groups.reduce((total, group) => {
                    return group.childrenCount + total;
                }, 0);

                this.Alert.showConfirm({
                    title: this.$translate('USER_OVERAGE_CONFIRM_TITLE'),
                    text: this.$translate('USER_OVERAGE_CONFIRM_TEXT', {
                        total: (adultsCount * 22) + (childrenCount * 11),
                    }),
                    button: {
                        label: this.$translate('USER_OVERAGE_CONFIRM_ACTION'),
                        classes: ['bold'],
                    },
                }).then(() => {
                    this.submit();
                });
            });
        }
        else {
            this.submit();
        }
    }

    submit() {
        this.Http.post('/restaurant/groups', {
            groups: this.groups,
            date: this.$stateParams.date,
            type: this.$stateParams.type
        }).then((response) => {
            this.Calendar.resetCache();
            this.User.resetCache();

            this.Navigation.goBack();
        }).catch((response) => {
            this.Alert.showToast({
                text: this.$translate(response.data),
                duration: 2500,
            })
        });
    }
}

export default BookingController;
