import { defaultTo } from 'lodash-es';

class KidsController {
    constructor(User, Navigation, Kids, Calendar, Alert, $state, $stateParams, $filter) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Calendar = Calendar;
        this.Alert = Alert;
        this.Kids = Kids;

        this.$translate = $filter('translate');
        this.formatDate = $filter('formatDate');
        this.$state = $state;
        this.$stateParams = $stateParams;

        this.clubs = {};
    }

    $onInit() {
        this.Navigation.setTitle(this.$translate('CALENDAR_KIDS_TITLE'));

        this.placesResolver.then((places) => {
            this.clubs = places.find((row) => {
                if (row.type === 'KID') {
                    return row.items;
                }
            });
        });
    }

    getClubDetail(item) {
        return this.$translate(`CLUB_DETAIL_${item.type}`)
    }

    isMode(mode) {
        return defaultTo(this.$stateParams.mode, 'discover') === mode;
    }

    userHasAccessToClub(club) {
        return !(club.type === 'CHICKS' && this.User.getUser().mode === 1);
    }

    submit() {
        if (!this.birthdate) {
            this.Alert.showConfirm({
                title: this.$translate('CALENDAR_BOOKING_ERROR'),
                text: this.$translate('FORM_ERROR_DATE_MISSING'),
            });

            return;
        }

        let month = moment().diff(this.birthdate, 'month');

        let foundClub = this.Kids.getRequirements().find((reqs) => {
            return reqs.minimum_age <= month && reqs.maximum_age >= month;
        });

        if (!foundClub) {
            this.Alert.showConfirm({
                title: this.$translate('CALENDAR_BOOKING_ERROR'),
                text: this.$translate('KIDS_NO_CLUB_FOR_AGE'),
            });

            return;
        }

        if (foundClub.type === 'CHICKS' && this.User.getUser().mode === 1) {
            this.Alert.showConfirm({
                title: this.$translate('CALENDAR_BOOKING_ERROR'),
                text: this.$translate('KIDS_NO_CLUB_FOR_RENTAL'),
            });

            return;
        }

        let club = this.clubs.items.find((club) => {
            return club.type === foundClub.type;
        });

        if (!club) {
            this.Alert.showConfirm({
                title: this.$translate('CALENDAR_BOOKING_ERROR'),
                text: this.$translate('KIDS_NO_CLUB_FOR_AGE'),
            });

            return;
        }

        this.$state.go('auth.calendar.kids.register', {
            id: club.clubData.id,
            name: this.name,
            firstname: this.firstname,
            birthdate: this.formatDate(this.birthdate, 'sql'),
        }, { reload: true, inherit: false });
    }
}

export default KidsController;
