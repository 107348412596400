const parameters = require(`./parameters/${APP_NAME}.js`);

const WebFont = require('webfontloader');

import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularTranslate from 'angular-translate';
import angularAnimate from 'angular-animate';
import digestHud from 'angular-digest-hud';

import 'angular-local-storage';
import 'angular-sanitize';
import 'angular-moment-picker';

import 'normalize.css';
import 'font-awesome/css/font-awesome.min.css';
import 'angular-moment-picker/dist/angular-moment-picker.css';

require(`./styles/style.scss`);

if (IS_APP) {
    require(`./styles/_notch.scss`);
}

import translations from './translations/translations';

import components from './components/components';
import core from './core/core';
import services from './services/services';

import httpInterceptor from './helpers/http-interceptor';

import { detect } from 'detect-browser';

let Plugins;
let StatusBarStyle;
let StatusBar;

if (IS_APP) {
    Plugins = require('@capacitor/core').Plugins;
    StatusBarStyle = require('@capacitor/core').StatusBarStyle;
    StatusBar = Plugins.StatusBar;
}

angular
    .module('app', [
        'ngSanitize',
        uiRouter,
        angularTranslate,
        angularAnimate,
        'LocalStorageModule',
        'moment-picker',
        'digestHud',
        components,
        core,
        services,
    ])
    .factory('$httpInterceptor', httpInterceptor)
    .config(translations)
    .config((digestHudProvider) => {
        "ngInject";

        if (!IS_PROD) {
            digestHudProvider.enable();
        }

        digestHudProvider.setHudPosition('top right');
        digestHudProvider.numTopWatches = 20;
        digestHudProvider.numDigestStats = 25;
    })
    .config((localStorageServiceProvider) => {
        "ngInject";

        localStorageServiceProvider
            .setPrefix('bb.')
            .setDefaultToCookie(false)
            .setNotify(false, false);
    })
    .config(($translateProvider) => {
        "ngInject";

        $translateProvider.useSanitizeValueStrategy(null);
    })
    .config(($compileProvider) => {
        "ngInject";

        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|javascript|tel|local|data|capacitor):/);
        $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|local|data|chrome-extension|capacitor):/);
        $compileProvider.commentDirectivesEnabled(false);
        $compileProvider.cssClassDirectivesEnabled(false);

        if (IS_PROD) {
            $compileProvider.debugInfoEnabled(false);
        }
    })
    .config((CacheFactoryProvider) => {
        "ngInject";

        angular.extend(CacheFactoryProvider.defaults, {
            storageMode: 'sessionStorage',
            storagePrefix: 'bb.cached.',
            storageImpl: {
                getItem: (key) => {
                    return sessionStorage.getItem(key);
                },
                setItem: (key, value) => {
                    try {
                        sessionStorage.setItem(key, value);
                    }
                    catch(e) {}
                },
                removeItem: (key) => {
                    sessionStorage.removeItem(key);
                },
            },
            capacity: 30,
        });
    })
    .config(($urlRouterProvider, $locationProvider) => {
        "ngInject";

        $urlRouterProvider.otherwise(() => {
            return {
                state: parameters.home_state_name,
            }
        });

        $locationProvider.html5Mode(true);
    })
    .config(($httpProvider) => {
        "ngInject";

        $httpProvider.useApplyAsync(true);
        $httpProvider.interceptors.push('$httpInterceptor');
    })
    .config(($qProvider) => {
        "ngInject";

        $qProvider.errorOnUnhandledRejections(false);
    })
    .run(() => {
        if (IS_APP) {
            StatusBar.setStyle({
                style: StatusBarStyle.Dark
            });
        }
    })
    .run((Config) => {
        "ngInject";

        let browser = detect();

        let isSafari = !!(browser && (
            browser.name === 'safari' ||
            browser.name === 'ios' ||
            browser.name === 'ios-webview'
        ));

        let isMobile = !!(browser && (
            browser.os === 'Android OS' ||
            browser.os === 'iOS'
        ));

        Config.setCurrentPlatform(angular.extend({}, parameters, {
            'api_url': `${parameters.static_url}/api`,
            isCordova: !!window.cordova,
            isMsie: !!document.documentMode,
            isSafari: isSafari,
            isMobileOS: isMobile,
        }));
    })
    .run(($translate) => {
        moment.locale($translate.use());
    })
    .run((Config, $window) => {
        "ngInject";

        $window.document.title = Config.title;

        WebFont.load({
            google: {
                families: [
                    'Raleway:400,600,700',
                ],
            },
        });
    })
    .run((User, Navigation, Config, $state, $transitions) => {
        "ngInject";

        $transitions.onBefore({ to: Config.login_state_name }, () => {
            if (User.isLogged()) {
                return $state.target(Config.home_state_name);
            }
        });

        $transitions.onBefore({ to: 'auth.**' }, (transition) => {
            if (!User.isLogged()) {
                return $state.target(Config.login_state_name);
            }

            return User.getUserDetail().then((userDetail) => {
                if (transition.to().name !== Config.profile_form_state_name) {
                    if (!userDetail) {
                        return $state.target(Config.profile_form_state_name);
                    }

                    if (!userDetail.checked) {
                        return $state.target(Config.profile_form_state_name);
                    }
                }
            });
        });

        $transitions.onSuccess({}, (transition) => {
            let { stealth = null, flush = null } = angular.extend({}, transition.options().custom, transition.to().data);

            if (stealth) {
                return;
            }

            if (flush) {
                Navigation.flush();

                return;
            }

            let state = transition.from();
            let params = transition.params('from');

            if (!state.abstract) {
                Navigation.push(state, params);
            }
        });

        $state.defaultErrorHandler((error) => {
            if (!IS_PROD) {
                console.error('$state.defaultErrorHandler()', error);
            }
        });

        $transitions.onError({}, (transition) => {
            if (!IS_PROD) {
                let error = transition.error();

                console.error('$transitions.onError()', error);
            }
        });
    });
