module.exports = {
    "title": "[PP] Mon Séjour Belambra",
    "theme_color": "#971C5F",
    "language": "fr",
    "logo_path": "/images/logo.png",
    "home_state_name": "auth.summary",
    "calendar_state_name": "auth.calendar",
    "profile_form_state_name": "auth.profile.form",
    "login_state_name": "login",
    "api_url": "https://msb.mybelambra.fr/api",
    "static_url": "https://msb.mybelambra.fr",
    "upload_path": "images/library",
};

